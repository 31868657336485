@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  background: white;
  height: 54px;
}

.searchbar {
  position: fixed;
  top: 54px;
  width: 100%;
  background: white;
  padding: 32px 20px;
  z-index: 2;
}

@media (min-width: 768px) {
  .searchbar {
    top: 48px;
    padding: 48px 20px;
  }
}

.table-box {
  position: relative;
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  margin-top: 200px;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .table-box {
    max-width: 1024px;
  }
}

.player-table {
  position: relative;
}

.player-table-head {
  border-bottom: 1px solid #000000;
  z-index: 4;
  position: sticky;
  top: 174px;
  max-width: 576px;
  width: 100%;
  background: white;
}

@media (min-width: 768px) {
  .player-table-head {
    max-width: 1024px;
    border-bottom: none;
    top: 175px;
  }
}

.player-table-head .player-table-row {
  display: grid;
  grid-template-columns: 0 140px 1fr 1fr 1fr 1fr;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #000000;
  font-weight: 800;
  text-align: center;
}

@media (min-width: 768px) {
  .player-table-head .player-table-row {
    grid-template-columns: 250px 180px 1fr 1fr 1fr 1fr;
  }
}

.player-table-body {
  min-height: 140px;
  color: #ffffff;
  margin: 0 -20px;
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .player-table-body {
    margin: initial;
    overflow: auto;
  }
}

.player-table-body .player-table-row {
  display: grid;
  padding: 0 20px;
  border-bottom: 1px solid #ffffff;
  grid-template-columns: 140px 1fr 1fr 1fr 1fr;
  grid-template-areas: 'name name name name name' 'team from to pos ht';
  min-height: 85px;
}

@media (min-width: 768px) {
  .player-table-body .player-table-row {
    background: 0 0;
    padding: initial;
    grid-template-columns: 250px 180px 1fr 1fr 1fr 1fr;
    grid-template-areas: 'name team from to pos ht';
  }
}

.player-table-body .player-table-row .player-table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  background: #7a7c7e;
  border: 1px solid #ffffff;
  border-bottom: none;
  border-left: none;
  position: relative;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .player-table-body .player-table-row .player-table-cell {
    border-top: none;
    font-size: 16px;
  }
}

.player-table-body .player-table-row .player-table-cell .arrow {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 1px;
}

.player-table-body .player-table-row .player-table-cell > div {
  padding: 14px 8px;
}

.player-table-body .player-table-row .player-table-cell:first-child {
  background: 0 0;
  color: #000000;
  grid-area: name;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .player-table-body .player-table-row .player-table-cell:first-child {
    background: #7a7c7e;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.player-table-body .player-table-row .player-table-cell:first-child > div {
  padding: 16px 0;
}

@media (min-width: 768px) {
  .player-table-body .player-table-row .player-table-cell:first-child.green {
    background: #79a86b;
  }
}

.player-table-body .player-table-row .player-table-cell:nth-child(2) {
  grid-area: team;
}

.player-table-body .player-table-row .player-table-cell:nth-child(3) {
  grid-area: from;
}

.player-table-body .player-table-row .player-table-cell:nth-child(4) {
  grid-area: to;
}

.player-table-body .player-table-row .player-table-cell:nth-child(5) {
  grid-area: pos;
}

.player-table-body .player-table-row .player-table-cell:nth-child(6) {
  grid-area: ht;
  border-right: none;
}

.player-table-body
  .player-table-row
  .player-table-cell.green:not(:first-child) {
  background-color: #79a86b;
}

.player-table-body .player-table-row .player-table-cell.yellow {
  background-color: #c6b566;
}

.player-table-body .player-table-row.lost .player-table-cell:first-child {
  background-color: #ffffff;
}

.player-table-body .player-table-row.lost .player-table-cell:not(:first-child) {
  background-color: #000000;
}

@media (min-width: 768px) {
  .player-table-body .player-table-row.lost {
    background: #000000;
  }

  .player-table-body .player-table-row.lost .player-table-cell:first-child {
    background-color: #000000;
  }
}

.green-line {
  padding: 3px 3px;
  background-color: #79a86b;
  color: #ffffff;
}

.yellow-line {
  padding: 3px 3px;
  background-color: #c6b566;
  color: #ffffff;
}

.coffee {
  height: 60px !important;
  width: 217px !important;
}
